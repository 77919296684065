<template>
  <div id="app" class="main-wrapper" :class="{'scroll-locked': modalOpen}">
    <v-header v-if="!isNotFoundPage"></v-header>
    <main class="page-wrapper">
      <router-view></router-view>
    </main>
    <v-footer v-if="!isNotFoundPage"></v-footer>
    <div class="modal-backdrop" v-if="modalOpen"></div>
  </div>
</template>

<script>
import VHeader from '@/components/VHeader';
import VFooter from '@/components/VFooter';

export default {
  name: 'App',
  components: {
    VHeader,
    VFooter,
  },
  computed: {
    isNotFoundPage() {
      return this.$route.name === 'notFound'
    },
    modalOpen() {
      return this.$store.state.modalOpen
    },
  },
  watch: {
    modalOpen(val) {
      if(val) {
        window.document.body.classList.add('scroll-locked');
      } else {
        window.document.body.classList.remove('scroll-locked');
      }
    }
  }
}
</script>

<style>

</style>
