import {createStore} from 'vuex'

export default createStore({
  state() {
    return {
      modalOpen: false
    }
  },
  mutations: {

  }
})