import { createApp } from 'vue'
import App from './App.vue'
import Router from './router'
import { vMaska } from "maska"
import vSelect from "vue-select";
import store from './store/index'
import VueSmoothScroll from 'vue3-smooth-scroll'
import BootstrapGrid from 'bootstrap/dist/css/bootstrap-grid.css'
import 'animate.css'
import 'vue-select/dist/vue-select.css';
import '@/assets/style/style.scss';


const app = createApp(App)

app.use(Router)
app.use(store)
app.use(VueSmoothScroll)
app.directive("maska", vMaska)
app.component("v-select", vSelect)
app.use(BootstrapGrid)
app.mount('#app')

