<template>
  <header class="header">
    <div class="container">
      <nav class="header-row">
        <div class="mobile-header-top">
          <router-link to="/" class="header-logo" @click="closeMenu">
            <img src="@/assets/img/logo/logo-text.svg" alt="Логотип">
          </router-link>
          <button class="toggle-mobile-menu" @click="toggleMenu" :class="{active : menuOpen}">
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
        <ul class="header-nav" :class="{show : menuOpen}">
          <li>
            <a @click="handleNav('/#app')" href="#app" v-smooth-scroll="{duration: 800}">Жүктеу</a>
          </li>
          <li>
            <a @click="handleNav('/#about')" href="#about" v-smooth-scroll="{duration: 800}">Жоба жайлы</a>
          </li>
          <li>
            <router-link @click="handleNav('')" to="/shop" active-class="active">Дүкен</router-link>
          </li>
          <li>
            <router-link @click="handleNav('')" to="/news" active-class="active">Жаңалықтар</router-link>
          </li>
          <li>
            <a @click="handleNav('/#author')" href="#author" v-smooth-scroll="{duration: 800}">Авторларға</a>
          </li>
          <li>
            <a @click="handleNav('/#contact')" href="#contact" v-smooth-scroll="{duration: 800}">Байланысу</a>
          </li>
        </ul>
        <a href="tel:+77784659000" class="header-phone">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 5V19C18 20.1046 17.1046 21 16 21H8C6.89543 21 6 20.1046 6 19V5C6 3.89543 6.89543 3 8 3H16C17.1046 3 18 3.89543 18 5" stroke="#4090FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 18H14" stroke="#4090FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span>+7(778) 465 90 00</span>
        </a>
        <!--Пока корзины нет, в будущем добавится, class "active"-->
<!--        <a href="#" class="header-basket">-->
<!--          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M5.96905 6.625L5.30205 3.625H3.37305" stroke="black" stroke-opacity="0.6" stroke-width="1.5"-->
<!--                  stroke-linecap="round" stroke-linejoin="round"/>-->
<!--            <path fill-rule="evenodd" clip-rule="evenodd"-->
<!--                  d="M7.73124 14.835L5.96924 6.625H18.6272C19.2642 6.625 19.7382 7.212 19.6052 7.835L18.1032 14.835C18.0042 15.296 17.5972 15.625 17.1252 15.625H8.70824C8.23724 15.625 7.83024 15.296 7.73124 14.835Z"-->
<!--                  stroke="black" stroke-opacity="0.6" stroke-width="1.5" stroke-linecap="round"-->
<!--                  stroke-linejoin="round"/>-->
<!--            <path-->
<!--                d="M17.4648 19.25C17.2578 19.25 17.0898 19.418 17.0918 19.625C17.0918 19.832 17.2598 20 17.4668 20C17.6738 20 17.8418 19.832 17.8418 19.625C17.8408 19.418 17.6728 19.25 17.4648 19.25"-->
<!--                stroke="black" stroke-opacity="0.6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--            <path-->
<!--                d="M8.85593 19.25C8.64893 19.25 8.48093 19.418 8.48293 19.625C8.48193 19.832 8.64993 20 8.85693 20C9.06393 20 9.23193 19.832 9.23193 19.625C9.23193 19.418 9.06393 19.25 8.85593 19.25"-->
<!--                stroke="black" stroke-opacity="0.6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--          </svg>-->
<!--          <span>Корзина пуста</span>-->
<!--        </a>-->
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: "VHeader",

  data() {
    return {
      menuOpen: false,
    }
  },

  watch: {
    menuOpen() {
      if(this.menuOpen) {
        document.body.classList.add('scroll-locked');
      } else {
        document.body.classList.remove('scroll-locked');
      }
    }
  },

  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen
    },
    handleNav(hash) {
      this.menuOpen = false;
      if(this.$route.name !== 'home' && hash) {
        window.location.href = hash
      }
    }
  },
}

</script>

<style scoped>

</style>