import { createRouter, createWebHistory } from 'vue-router';
import {h} from "vue";

const checkDevice = function () {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.ordagen'
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    window.location.href = 'https://apps.apple.com/us/app/ordagen/id1526116038'
  }

  return "/";
}


export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      alias: '/home',
      component: () => import('../views/VHome'),
    },
    {
      path: '/basket',
      name: 'basket',
      component: () => import('../views/VBasket'),
    },
    {
      path: '/product/:id',
      name: 'product',
      component: () => import('../views/VProduct'),
    },
    {
      path: '/shop',
      name: 'shop',
      component: () => import('../views/VShop'),
    },
    {
      path: '/license',
      name: 'license',
      component: () => import('../views/VPrivacyPolicy'),
    },
    {
      path: '/audio/:pathMatch(.*)*',
      name: 'audio',
      beforeEnter: (from) => {
        let url = from.fullPath
        window.location.href = `https://new.ordagen.com${url}`
      },
    },
    {
      path: '/download',
      name: 'download',
      beforeEnter: checkDevice
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'error',
      component: () => import('../views/VNotFoundPage'),
    },
    {
      path: '/news',
      render: () => {
        return h('router-view')
      },
      children: [
        {
          path: '',
          name: 'VNews',
          component: () => import('../views/VNews/index.vue')
        },
        {
          path: '/news/:id',
          name: 'VNewsDetail',
          component: () => import('../views/VNews/VNewsDetail/index.vue'),
        },
        {
          path: ':id/rubric',
          name: 'VNewsRubric',
          component: () => import('../views/VNews/VNewsRubric/index.vue'),
        }
      ]
    }
  ]
})


